body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}
/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #999;
}
/* remove up down arrows in input type of number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* submit button design */
.submit_button {
  min-width: 80px;
  padding: 8px;
  background-color: #1e5b87;
  color: white;
  border: 1px solid #1e5b87;
  border-radius: 4px;
  cursor: pointer;
}

.submit_button:hover {
  background-color: #154163;
  border: 1px solid #154163;
}

.submit_button:disabled {
  padding: 8px;
  background-color: #76a4c7;
  color: white;
  border: 1px solid #76a4c7;
  border-radius: 4px;
  cursor: default;
}
/* default button design - edit */
.default_button {
  min-width: 45px;
  padding: 8px;
  height: 35px;
  background-color: #1e5b87;
  color: white;
  border: 1px solid #1e5b87;
  border-radius: 4px;
  cursor: pointer;
}

.default_button:hover {
  background-color: #154163;
  border: 1px solid #154163;
}

.default_button:disabled {
  padding: 3px;
  background-color: #76a4c7;
  color: white;
  border: 1px solid #76a4c7;
  border-radius: 4px;
  cursor: default;
}
.default_button .MuiSvgIcon-root {
  font-size: 1.2rem;
}

/* delete button design */
.delete_button {
  min-width: 50px;
  padding: 3px;
  background-color: #e02121;
  color: white;
  border: 1px solid #e02121;
  border-radius: 4px;
  cursor: pointer;
}

.delete_button:hover {
  background-color: #f71a1a;
  border: 1px solid #f71a1a;
}
/* all   head css */
.MuiTable-root .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root {
  padding: 0px 16px;
}
.MuiTableRow-root:hover {
  background: #4cacf224 !important;
  cursor: pointer;
}

/* for all the selected tabs */
/* .tabs-wrapper {
  width: 100%;
  border: 1px solid #ddd;
  box-sizing: border-box;
  box-shadow: inset 0 0 10px #888;
  border-radius: 4px; 
} */

/* .MuiTabs-scroller .MuiTabs-flexContainer .MuiButtonBase-root.Mui-selected {
  border-bottom: 1px solid #1e5b87;
  background: #1e5b87;
  color: #1e5b87;
  border-radius: 4px;
} */

body {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}
.container {
  height: auto;
  position: relative;
}
.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.ScrollableContainer {
  /* height: 580px; */
  overflow-y: scroll;
}

.modal-close-button {
  position: absolute !important;
  right: 10px;
  top: 6px;
  background: #3575a8 !important;
  color: #fff !important;
  padding: 2px !important;
  font-size: 1rem !important;
  transition: 0.2s ease-in-out 0s !important;
}

.modal-close-button:hover {
  background: #1e5b87 !important;
  transform: scale(1.12);
}

/* for add circle icon button */
.add-button-wrapper {
  padding-left: 1rem !important;
  max-width: 5.1111% !important;
}
.add-button-wrapper .modal-add-button {
  padding: 1px !important;
}

.add-button-wrapper .modal-add-button svg {
  font-size: 2rem !important;
}

/* end - for add circle icon button */
.css-wgai2y-MuiFormLabel-asterisk {
  color: red;
}
input[type="number"]:disabled {
  background: #e9ecef;
}
.disabled__text {
  background: #e9ecef;
}
/* #purchaseForm input {
  padding-left: 1px;
} */
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  font-weight: 700 !important ;
}
/* font size for  */
@media screen and (max-width: 1366px) {
  .MuiInputBase-input {
    font-size: 12px !important;
  }
}
.MuiInputBase-input {
  font-size: 15px !important;
}
/* for delete icon */
.delete_button svg {
  font-size: 1.3rem !important;
}
.css-1t4vnk2-MuiDialogContent-root {
  padding: 10px !important;
}

/* css for buttons in item - packingTypeDetail buttons */

.add-packing-detail-button {
  min-width: 80px;
  padding: 8px;
  background-color: #1e5b87;
  color: white;
  border: 1px solid #1e5b87;
  border-radius: 4px;
  cursor: pointer;
  position: absolute;
  right: 1%;
  bottom: 10%;
}

.delete-packing-detail-button {
  min-width: 35px;
  padding: 2px;
  background-color: #d32f2f;
  color: white;
  border: 1px solid #d32f2f;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  left: 30%;
  top: 33%;
}
/* table td {
  font-size: 13.4px !important;
} */
.MuiTableCell-root {
  padding: 0 5px !important;
}
.MuiTableCell-root .MuiGrid-container {
  flex-wrap: nowrap;
}
.centerItemButtonInModal {
  margin: 0;
  position: relative;
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%);
}
/* .containerModal { 
  height: 200px;
  position: relative;
  border: 3px solid green; 
} */

.centerCss {
  margin: 0;
  position: absolute;
  top: 45%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.purchase-report-center {
  display: inline-block;
  margin: 0 auto;
  padding: 3px;
  width: 60%;
  height: auto;
}

.center-purchase-report {
  width: 100%;
  text-align: center;
  height: 600px;
  background: #f5f5f5;
}
.purchase-report-center-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
}
/* line height of table data */
.css-40fx44-MuiTableCell-root {
  line-height: 1.3 !important;
}
/* style the table color of the table head */
.custom-table thead th {
  background-color: #1e5b87 !important;
  color: whitesmoke !important;
  white-space: nowrap;
}
.custom-table
  .css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active {
  color: whitesmoke !important;
}
.custom-table .css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root:hover {
  color: whitesmoke !important;
}
.custom-table
  .css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active
  .MuiTableSortLabel-icon
  :hover {
  color: whitesmoke !important;
}
.custom-table
  .css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active
  .MuiTableSortLabel-icon {
  color: whitesmoke !important;
}
.custom-table .css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  color: whitesmoke !important;
}
/* style table when it is selected */
.css-1q1u3t4-MuiTableRow-root.Mui-selected {
  background-color: rgba(25, 118, 210, 0.4) !important;
}
/* set the font size  */
.status {
  font-size: 11.5px !important;
}
input :disabled {
  color: grey;
}

/* selected patient in appointment module - css */
.selected-patient-chip {
  background: #2d4d7b !important;
  color: #fff !important;
  font-size: 0.9rem !important;
  font-family: unset !important;
  font-weight: 400 !important;
}
.selected-patient-chip .MuiAvatar-root {
  background: #fff !important;
  color: #2d4d7b !important;
  font-weight: 600 !important;
}
/* visibility icons style*/
.MuiInputAdornment-root .MuiSvgIcon-root {
  padding: 0.5rem !important;
}
.MuiInputAdornment-root .MuiSvgIcon-root:hover {
  cursor: pointer;
}

/* checkbox padding   center*/
/* .center-checkbox-field{
  padding-top:"7px";
} */
