.form-wrapper {
  position: relative;
  width: auto;
  height: 100vh;
  background: linear-gradient(
    to top,
      /* rgba(68, 189, 234, 255),
      rgba(59, 150, 220, 255),
      rgba(52, 104, 206, 255) */
      rgba(204, 0, 0, 0.8),
      rgba(8, 188, 255, 0.8)
  );
  font-family: sans-serif, Arial;
}

.form-wrapper .paperStyle {
  position: relative;
  top: 14%;
  width: 60%;
  height: auto;
  margin: auto;
}


.form-wrapper .paperStyle .form-container .meraki-logo {
  width: 45px;
  height: 35px;
}

.form-wrapper .paperStyle .form-container h4 {
  font-weight: 700;
  color: rgba(204, 0, 0, 0.7);
}

.form-wrapper .paperStyle .form-container {
  padding: 44px 20px;
  background-color: #e8f0fe;
}
.form-wrapper .paperStyle .organization-logo-container {
  position: relative;
  height: 495px;
  width: 100%;
  border-radius: 0 4px 4px 0;
  background: linear-gradient(to top,
      /* rgba(68, 189, 234, 255),
        rgba(59, 150, 220, 255),
        rgba(52, 104, 206, 255) */
      rgba(204, 0, 0, 0.8),
      rgba(8, 188, 255, 0.8));;
  /* background-image: url("../../../Assets/login_background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
}

.form-wrapper
  .paperStyle
  .organization-logo-container
  .organization-logo-wrapper {
  width: 100%;
  height: 100%;
}

.form-wrapper
  .paperStyle
  .organization-logo-container
  .organization-logo-wrapper
  .organization-logo-item {
  width: 70px;
  height: 70px;
}
.form-wrapper
.paperStyle
.organization-logo-container
.organization-logo-wrapper
.organization-logo {
  width: 100%;
  height: 100%;
}
.organization_content{
  text-align: center;
}
.organization-logo-wrapper .organization_content .organization_title{
  font-family: 'Poppins', sans-serif;
  font-size: 1.8rem;
  font-weight: 600;
  letter-spacing: 1.5px;
}
.organization-logo-wrapper .organization_content .organization_description{
  font-family: 'Poppins', sans-serif;
  font-size: .8rem;
  font-weight: 400;
}
.organization_link_content .organization_link_item{
  text-decoration: none;
  color: #000000;
}
.organization_link_item .organization_link_icon{
  font-size: 18px;
}
.organization_link_item .organization_link_text{
  font-family: 'Poppins', sans-serif;
  font-size: .9rem;
  text-decoration: none;
  color: #000000;
}

.form-wrapper .meraki-tag-container {
  position: relative;
  top: 18%;
}

.form-wrapper .meraki-tag-container .meraki-tag {
  color: #fff;
}
.form-wrapper .meraki-tag-container .meraki-tag .meraki-link{
  color: #fff;
  text-decoration: none;
}

@media (max-width: 768px) {
  .form-wrapper .paperStyle .paper-style-wrapper {
    display: flex;
    flex-direction: column;
  }
  .form-wrapper .paperStyle .form-container {
    padding: 10px;
    max-width: 100% !important;
    text-align: center;
  }
  .form-wrapper .paperStyle .form-container h4 {
    font-weight: 700;
    font-size: 1.6rem;
  }
  .form-wrapper .paperStyle .organization-logo-container {
    display: none;
  }
  .form-wrapper .paperStyle .form-container .button-wrapper {
    justify-content: center;
    align-items: center;
  }
}
@media (max-height: 550px) and (min-height: 520px) {
  .form-wrapper {
    height: 100vh;
  }
  .form-wrapper .paperStyle {
    top: 11%;
  }
  .form-wrapper .meraki-tag-container {
    top: 13%;
  }

  .form-wrapper
    .paperStyle
    .organization-logo-container
    .organization-logo-wrapper
    .organization-logo-item {
    width: 190px;
    height: 190px;
  }
}

@media (max-height: 519px) and (min-height: 500px) {
  .form-wrapper {
    height: 100vh;
  }
  .form-wrapper .paperStyle {
    top: 10%;
  }
  .form-wrapper .meraki-tag-container {
    top: 12%;
  }
  .form-wrapper
    .paperStyle
    .organization-logo-container
    .organization-logo-wrapper
    .organization-logo-item {
    width: 180px;
    height: 180px;
  }
}

@media (max-height: 499px) and (min-height: 450px) {
  .form-wrapper {
    height: 100vh;
  }
  .form-wrapper .paperStyle {
    top: 8%;
  }
  .form-wrapper .meraki-tag-container {
    top: 10%;
  }
  .form-wrapper
    .paperStyle
    .organization-logo-container
    .organization-logo-wrapper
    .organization-logo-item {
    width: 170px;
    height: 170px;
  }
}

.text-decoration-none {
  text-decoration: none;
  color: #000000;
}