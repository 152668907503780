.sidebarDrawer {
  z-index: 100;
  position: fixed !important;
  top: 39.5px;
}
.sidebarDrawer h6 {
  font-size: 1.1rem;
}
.sidebarListWrapper {
  display: block;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 72vh;
}
.sidebarListWrapper .MuiListItemText-root .MuiTypography-root {
  font-size: 0.85rem !important;
  margin: 1px 2px !important;
  transition: all 0.3s;
}
.sidebarListWrapper .MuiListItemText-root .MuiTypography-root:hover {
  margin: 1px 6px !important;
}
.search-wrapper-sidebar {
  display: flex;
  justify-content: space-between;
  margin-right: 0px !important;
}

.search-wrapper-sidebar .MuiInputBase-root input {
  padding-left: 10px !important;
  font-size: 0.9rem !important;
}

.sidebar-search-icon-wrapper {
  margin-top: 1px;
  position: absolute !important;
  margin-left: 85% !important;
}
.sidebar-search-icon-wrapper .MuiSvgIcon-root {
  font-size: 1.4rem !important;
  color: #aaa;
}
.sidebar_logo {
  display: flex;
  flex-flow: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 4px 2px;
}
.sidebar_logo .fullLogo {
  width: 80px;
  height: 55px;
}

.sidebar_logo .shrinkLogo {
  width: 44px;
  height: 30px;
}

.sub-menu-design {
  list-style-type: disc;
  margin: 2px;
}
