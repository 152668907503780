.fixedHeaderIcons {
  position: absolute;
  margin-left: 86% !important;
}

.fixed-header-button {
  text-transform: uppercase;
  min-width: 5.5rem;
  border: 1px solid #f2f2f2;
  border-radius: 6px;
  /* padding: 6px 6px; */
  color: #f2f2f2;
  background-color: #1e5b87;
  margin: 0 2px;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 0.2px;
  width: 100;
  max-height: 30px;
}

.activeButton {
  background-color: #f2f2f2;
  border: 1px solid #0e0f10;
  color: #1e5b87;
  margin: 0 2px;
  /* padding: 6px 6px; */
}

.secondary-header {
  top: 40px !important;
  box-shadow: none !important;
  background-color: #1e5b87;
  z-index: 100 !important;
}
  
#main-searchBar-container {
  position: absolute;
  margin-left: 32%;
}
.MuiGrid-root .secondary-header {
  background: rgba(233, 233, 230, 1);
  z-index: 100;
}

.search-wrapper {
  display: flex;
}
.search-wrapper-input {
  font-size: 0.9rem !important;
  width: 100%;
}

/* .search-wrapper-for-all{
  display: flex;
} */

/* .search-wrapper-for-all-input {
  font-size: 0.9rem !important;
  width: 200%;
} */
.search-icon-wrapper {
  margin-top: 2px;
  margin-right: 2px;
  position: absolute !important;
}
.search-icon-wrapper .MuiSvgIcon-root {
  font-size: 1.4rem !important;
  color: #aaa;
}
.search-wrapper .MuiInputBase-root input {
  padding-left: 6px !important;
  width: 100% !important;
}

/* responsive code - start */
/* --------- start code for search icon in top header --------- */
@media (min-width: 1380px) and (max-width: 2124px) {
  .search-icon-wrapper {
    margin-left: 92% !important;
  }
}
@media (min-width: 1200px) and (max-width: 1379px) {
  .search-icon-wrapper {
    margin-left: 90% !important;
  }
}
@media (min-width: 920px) and (max-width: 1199px) {
  .search-icon-wrapper {
    margin-left: 88% !important;
  }
}
@media (min-width: 820px) and (max-width: 919px) {
  .search-icon-wrapper {
    margin-left: 86% !important;
  }
}
@media (min-width: 590px) and (max-width: 819px) {
  .search-icon-wrapper {
    margin-left: 80% !important;
  }
}
@media (min-width: 400px) and (max-width: 589px) {
  .search-icon-wrapper {
    margin-left: 76% !important;
  }
}

/* --------- end code for search icon in top header --------- */

/* --------- start code for fixedHeaderIcons left shortcut, fullscreen, notification and account ---------- */
@media (min-width: 850px) and (max-width: 1024px) {
  .fixedHeaderIcons {
    margin-left: 80% !important;
  }
}

@media (min-width: 560px) and (max-width: 849px) {
  .fixedHeaderIcons {
    margin-left: 75% !important;
  }
}
@media (min-width: 400px) and (max-width: 559px) {
  .fixedHeaderIcons {
    margin-left: 70% !important;
  }
}
/* --------- end code for fixedHeaderIcons left shortcut, fullscreen, notification and account --------- */
/* responsive code - end */
